<template>
  <svg
    v-if="iconLib === 'fluent'"
    :width="size"
    :height="size"
    fill="none"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="(source, index) in pathSource"
      :key="source"
      :d="source"
      fill="currentColor"
      :class="`${icon}-${index + 1}`"
    />
    <rect
      v-for="(rect, ind) in rectSource"
      :key="ind"
      :class="`${icon}-${ind + 1}`"
      :x="`${rect['x']}`"
      :y="`${rect['y']}`"
      :width="`${rect['width']}`"
      :height="`${rect['height']}`"
      :rx="`${rect['rx']}`"
    />
  </svg>
  <svg
    v-else
    :width="size"
    :height="size"
    fill="none"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g v-for="(pathData, index) in pathSource" :key="index">
      <path
        :key="pathData"
        :d="pathData"
        stroke="currentColor"
        stroke-width="1.66667"
        :class="`${icon}-${index + 1}`"
      />
      <rect
        v-for="(rect, ind) in rectSource"
        :key="ind"
        :class="`${icon}-${ind + 1}`"
        :x="`${rect['x']}`"
        :y="`${rect['y']}`"
        :width="`${rect['width']}`"
        :height="`${rect['height']}`"
        :rx="`${rect['rx']}`"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    rects: {
      type: Object,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
    iconLib: {
      type: String,
      default: 'fluent',
    },
  },

  computed: {
    pathSource() {
      // To support icons with multiple paths
      const path = this.icons[`${this.icon}-${this.type}`];
      if (!path) return [];
      if (path.constructor === Array) {
        return path;
      }
      return [path];
    },
    rectSource() {
      const rect = this.rects[`${this.icon}-${this.type}`];
      if (!rect) return [];
      if (Array.isArray(rect)) return rect;
      return [rect];
    },
  },
};
</script>

<style>
.arrow-trending-lines-1,
.arrow-trending-lines-2,
.arrow-trending-lines-3 {
  fill: #d8d8d8;
}

.arrow-trending-lines-4 {
  fill: none;
}

.arrow-trending-lines-clicked-1,
.arrow-trending-lines-clicked-2,
.arrow-trending-lines-clicked-3 {
  fill: #1dc775;
}

.arrow-trending-lines-clicked-4 {
  fill: none;
}

.chat-1 {
  fill: #d8eae0;
}

.chat-clicked-1 {
  fill: #1dc775;
}

.chat-clicked-2,
.chat-clicked-3 {
  fill: #fff;
}

.book-contacts-1,
.book-contacts-2,
.book-contacts-3 {
  fill: #d8eae0;
}

.book-contacts-clicked-1,
.book-contacts-clicked-2,
.book-contacts-clicked-3 {
  fill: #1dc775;
}

.book-contacts-clicked-2,
.book-contacts-clicked-3 {
  fill: #fff;
}

.megaphone-1,
.megaphone-2 {
  fill: #d8eae0;
}

.megaphone-3 {
  fill: #b3beb8;
}

.megaphone-clicked-1,
.megaphone-clicked-2 {
  fill: #1dc775;
}

.megaphone-clicked-3 {
  fill: #20a562;
}

.settings-1 {
  fill: #d8eae0;
}

.settings-clicked-1 {
  fill: #1dc775;
}

.library-1 {
  fill: #cbd8d0;
}

.library-clicked-1 {
  fill: none;
}

.library-clicked-2 {
  fill: #1dc775;
}

.library-clicked-3,
.library-clicked-4 {
  fill: #fff;
}

.alert-1,
.alert-2 {
  fill: #cbd8d0;
}

.alert-clicked-1,
.alert-clicked-2 {
  fill: #1dc775;
}
</style>
