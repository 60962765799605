<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <span @mouseover="setAnimationTrue" @mouseleave="setAnimationFalse">
      <a
        v-tooltip.right="$t(`SIDEBAR.${name}`)"
        :href="href"
        class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
        :class="{
          'bg-woot-25 dark:bg-slate-800 text-woot-700 hover:bg-woot-100':
            isActive || isChildMenuActive,
        }"
        :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
        :target="openInNewPage ? '_blank' : undefined"
        @click="navigate"
      >
        <fluent-icon
          v-if="!animation"
          :viewBox="'0 0 31 31'"
          :icon="isConversationsActive(isActive) ? `${icon}-clicked` : icon"
          :size="25"
        />
        <span :class="animation ? '' : 'hidden'">
          <lottie-vue-player
            ref="lottiePlayer"
            :src="`/assets/animations/${keyItem}.json`"
            :show-color-picker="false"
            :loop="true"
            :autoplay="true"
            :player-controls="false"
            :style="{
              width: '25px',
              height: '25px',
              background: 'transparent',
            }"
          />
        </span>
        <span class="sr-only">{{ name }}</span>
        <span
          v-if="count"
          class="text-black-900 bg-yellow-500 absolute -top-1 -right-1"
        >
          {{ count }}
        </span>
      </a>
    </span>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
    keyItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      animation: false,
    };
  },
  methods: {
    setAnimationTrue() {
      this.animation = true;
    },
    setAnimationFalse() {
      this.animation = false;
    },
    isConversationsActive(isActive) {
      const basePathName = '/app/accounts';
      const allowedRoutes = [
        '/:accoundId/dashboard',
        '/:accoundId/mentions/conversations',
        '/:accountId/conversations/:conversationId',
        '/:accoundId/unattended/conversations',
        '/:accoundId/inbox/:inboxId',
        '/:accountId/inbox/:inboxId/conversations/:conversationId',
        '/:accoundId/label/:labelName',
      ];

      if (this.icon === 'chat') {
        const pathName = window.location.pathname.split('/');

        for (let i = 0; i < allowedRoutes.length; i += 1) {
          const allowedRoute = allowedRoutes[i].split('/');

          for (let j = 0; j < allowedRoute.length; j += 1) {
            if (allowedRoute[j].includes(':')) {
              allowedRoute[j] = pathName[j + 2];
            }
          }

          if (
            window.location.pathname ===
            basePathName.concat(allowedRoute.join('/'))
          ) {
            return true;
          }
        }

        return false;
      }
      return isActive;
    },
  },
};
</script>
