var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('span',{on:{"mouseover":_vm.setAnimationTrue,"mouseleave":_vm.setAnimationFalse}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t(`SIDEBAR.${_vm.name}`)),expression:"$t(`SIDEBAR.${name}`)",modifiers:{"right":true}}],staticClass:"text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative",class:{
        'bg-woot-25 dark:bg-slate-800 text-woot-700 hover:bg-woot-100':
          isActive || _vm.isChildMenuActive,
      },attrs:{"href":href,"rel":_vm.openInNewPage ? 'noopener noreferrer nofollow' : undefined,"target":_vm.openInNewPage ? '_blank' : undefined},on:{"click":navigate}},[(!_vm.animation)?_c('fluent-icon',{attrs:{"viewBox":'0 0 31 31',"icon":_vm.isConversationsActive(isActive) ? `${_vm.icon}-clicked` : _vm.icon,"size":25}}):_vm._e(),_vm._v(" "),_c('span',{class:_vm.animation ? '' : 'hidden'},[_c('lottie-vue-player',{ref:"lottiePlayer",style:({
            width: '25px',
            height: '25px',
            background: 'transparent',
          }),attrs:{"src":`/assets/animations/${_vm.keyItem}.json`,"show-color-picker":false,"loop":true,"autoplay":true,"player-controls":false}})],1),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.count)?_c('span',{staticClass:"text-black-900 bg-yellow-500 absolute -top-1 -right-1"},[_vm._v("\n        "+_vm._s(_vm.count)+"\n      ")]):_vm._e()],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }